
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { OfficeObject } from '@/store/modules/clients/OfficesModule';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'office-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    methods: {
      updateCoordinates(location) {
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.office.latitude = location.latLng.lat();
        this.office.longitude = location.latLng.lng();
      },
      addMarker(location) {
        console.log(location);
        this.markers.pop();

        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.markers.push({
          id: this.markers.length + 1,
          position: this.coordinates,
        });
        this.office.latitude = location.latLng.lat();
        this.office.longitude = location.latLng.lng();
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const officeMobile = computed(() => {
        const mobileString = String(office.value.mobile);
        const last7Digits = mobileString.slice(-10);
        return String(last7Digits);
      });

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('officeUpdating'), [
          translate('clients'),
        ]);

        await store.dispatch(Actions.GET_ALL_CLIENTS, { limit: 50 });
      });

      const { data } = await store.dispatch(
        Actions.GET_OFFICE,
        route.params.id
      );

      const office = ref<OfficeObject>(data.data);

      const coordinates: any = { lat: '', lng: '' };

      const markers = [
        {
          id: 1,
          position: {
            lat: 33.2209264,
            lng: 43.714387,
          },
        },
      ];
      const center = { lat: 33.2209264, lng: 43.714387 };

      if (office.value.latitude && office.value.longitude) {
        center.lat = Number(office.value.latitude);
        center.lng = Number(office.value.longitude);
        markers[0].position.lat = Number(office.value.latitude);
        markers[0].position.lng = Number(office.value.longitude);
      }

      const titleAr = () => {
        return office.value.name.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return office.value.name.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return office.value.name.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      const goBack = () => {
        router.back();
      };

      const officeSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        latitude: Yup.string()
          .required(() => translate('LATITUDE_IS_REQUIRED_FIELD'))
          .label('Latitude'),
        longitude: Yup.string()
          .required(() => translate('LONGITUDE_IS_REQUIRED_FIELD'))
          .label('Longitude'),
        clientId: Yup.string()
          .required(() => translate('CLIENT_IS_REQUIRED_FIELD'))
          .label('clientId'),
      });
      const onSubmitCreate = async (values) => {
        values.name = [];
        values.name.push({ languageCode: 'en', title: values.titleEn });
        values.name.push({ languageCode: 'ar', title: values.titleAr });
        values.name.push({ languageCode: 'ku', title: values.titleKu });
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.titleEn;
        delete values.titleAr;
        delete values.titleKu;
        values.isActive = true;

        values.mobile = '964' + values.mobile;

        await store.dispatch(Actions.UPDATE_OFFICE, {
          data: values,
          id: office.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_OFFICE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'offices-listing' });
          });
        }
      };

      return {
        coordinates,
        center,
        officeMobile,
        markers,
        onSubmitCreate,
        clients: computed(() => store.getters.allClientsList),
        selectedItem,
        submitButton,
        titleAr,
        titleEn,
        titleKu,
        translate,
        officeSchema,
        goBack,
        office,
        can,
      };
    },
  });
